import Popper from '@mui/material/Popper/Popper';
import { forwardRef, Fragment, KeyboardEvent, useCallback, useMemo, useState } from 'react';
import {
    CircularProgress,
    ClickAwayListener,
    Fade,
    IconButton,
    InputAdornment,
    Paper,
    Stack,
    StackProps,
    TextField,
    TextFieldProps,
    Tooltip,
    Typography,
} from '@mui/material';
import { getNull } from '@/utils/object.util';
import { Cancel01Icon } from 'hugeicons-react';
import { bindPopper } from 'material-ui-popup-state';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { findItemById, findParents } from '@/components/tree-select/TreeSelect.util';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { TreeView, TreeViewItem } from '@/components/tree-view/TreeView';
import { useTreeViewApiRef } from '@/components/tree-view/useTreeViewApiRef';

export type RecursiveValue = TreeViewItem;

export type TreeSelectionProps = Overwrite<
    TextFieldProps,
    {
        itemsData: RecursiveValue[];
        value?: Nullable<RecursiveValue>;
        onChange?: (value: RecursiveValue | null) => void;
        loading?: boolean;
    }
>;

/**
 * TreeSelect is a component like with a similar behaviour to Autocomplete but with a tree view as options.
 */
export const TreeSelect = forwardRef<HTMLDivElement, TreeSelectionProps>((props, ref) => {
    const { itemsData, value, onChange, loading, ...restTextField } = props;

    // state to store the input text field value displayed to the user
    const [inputValue, setInputValue] = useState<string>(value?.label ?? '');
    // state to store the search query when the user searching
    // we need to make the difference between the search query and the input value
    // because we filter tree items only when the user is searching
    const [searchQuery, setSearchQuery] = useState<string>('');

    const [expandedItems, setExpandedItems] = useState<string[]>([]);

    const popupState = usePopupState({ variant: 'popper', popupId: 'tree-select' });
    const { t } = useTranslation();

    const treeViewApiRef = useTreeViewApiRef();

    // init the tree view on open by expanding the parents of the selected item
    useDeepCompareEffect(() => {
        if (popupState.isOpen) {
            const parents = value ? findParents(itemsData, value.id) : [];
            setExpandedItems(parents.map(p => p.id.toString()));
        }
    }, [value, itemsData, popupState.isOpen]);

    // expansion control
    const handleItemExpansionToggle = (itemId: string, isExpanded: boolean) => {
        setExpandedItems(prev => (isExpanded ? [...prev, itemId] : prev.filter(id => id !== itemId)));
    };

    const filterAndExpand = useCallback((items: RecursiveValue[], searchValue: string): RecursiveValue[] => {
        return items
            .map(item => {
                // Recursively filter children
                const filteredChildren = filterAndExpand(item.children, searchValue);
                const foundInChildren = filteredChildren.length > 0;

                // Check if current item match the search
                const isMatch = item.label.trim().toLowerCase().includes(searchValue.trim().toLowerCase());

                // toggle parent's expand state
                handleItemExpansionToggle(item.id.toString(), foundInChildren);

                if (isMatch || foundInChildren) {
                    return {
                        ...item,
                        children: filteredChildren,
                    };
                }

                return undefined;
            })
            .filter((item): item is RecursiveValue => !!item); // Filter out non-matching items
    }, []);

    // filter items based on the search query
    const items = useMemo(() => (searchQuery ? filterAndExpand(itemsData, searchQuery) : itemsData), [filterAndExpand, itemsData, searchQuery]);

    // selection control
    const handleItemSelectionToggle = (itemId: string, isSelected: boolean) => {
        if (!isSelected) {
            onValueChange(getNull());
            return;
        }
        const newValue = findItemById(itemsData, Number(itemId));
        onValueChange(newValue ?? getNull());
        closePopper();
    };

    const handleSearch = (newSearchValue: string) => {
        setSearchQuery(newSearchValue);
        setInputValue(newSearchValue);
        // ensure the popup is open
        if (!popupState.isOpen) {
            popupState.open();
        }
        // reset when search value is empty
        if (!newSearchValue) {
            setExpandedItems([]);
        }
    };

    const handleClearValue = () => {
        onValueChange(getNull());
    };

    const onValueChange = (newValue: RecursiveValue | null) => {
        onChange?.(newValue);
        setSearchQuery('');
        setInputValue(newValue?.label ?? '');
    };

    const handleOnKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
        // select the item if there is only one item
        if (e.key === 'Enter' && items.length === 1) {
            onValueChange(items[0]);
        }
        if (e.key === 'ArrowDown') {
            // focus the value or the first item
            treeViewApiRef.current?.focusItem(e, value ? value.id.toString() : items[0].id.toString());
        }
    };

    const closePopper = () => {
        popupState.close();
        setExpandedItems([]);
    };

    const handleClickAway = () => {
        closePopper();
        setSearchQuery('');
        setInputValue(value?.label ?? '');
    };

    const parentsPath = value
        ? findParents(itemsData, value.id)
              .map(p => p.label)
              .join(' / ')
        : '';

    const inputSlotProps = {
        startAdornment: value ? (
            <Tooltip title={parentsPath}>
                <InputAdornment position='start' sx={{ pb: 0.25 }}>
                    <BreadCrumb items={findParents(itemsData, value.id)} sx={{ maxWidth: '150px' }} />
                </InputAdornment>
            </Tooltip>
        ) : undefined,
        endAdornment: value ? (
            <InputAdornment position='end'>
                <IconButton
                    onClick={e => {
                        handleClearValue();
                        e.stopPropagation();
                    }}
                >
                    <Cancel01Icon />
                </IconButton>
            </InputAdornment>
        ) : undefined,
    };

    // calculate the max height of the popper
    // returns the max available space between the anchor element and the top/bottom of the window
    const calculatePopperMaxHeight = () => {
        if (popupState.anchorEl) {
            const rect = popupState.anchorEl.getBoundingClientRect();
            const availableSpaceBelow = window.innerHeight - rect.bottom;
            const availableSpaceAbove = rect.top;
            return Math.max(availableSpaceBelow, availableSpaceAbove);
        }
        // Default value 400px
        return 400;
    };

    return (
        <>
            <TextField
                ref={ref}
                fullWidth
                autoComplete={'off'}
                {...restTextField}
                value={inputValue}
                onChange={e => handleSearch(e.target.value)}
                onKeyDown={handleOnKeyDown}
                slotProps={{
                    ...restTextField.slotProps,
                    input: { ...restTextField.slotProps?.input, ...inputSlotProps },
                    htmlInput: {
                        ...restTextField.slotProps?.htmlInput,
                        'aria-label': restTextField.name,
                    },
                }}
                {...bindTrigger(popupState)}
            />
            <Popper placement='bottom-start' {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleClickAway}>
                        <Fade {...TransitionProps}>
                            <Paper
                                variant='outlined'
                                sx={theme => ({
                                    width: popupState.anchorEl?.clientWidth ?? '500px',
                                    // remove the padding from the maxHeight
                                    maxHeight: `calc(${calculatePopperMaxHeight()}px - ${theme.spacing(2)})`,
                                    overflow: 'auto',
                                })}
                            >
                                <Stack gap={2} sx={{ p: 1 }}>
                                    {loading ? (
                                        <CircularProgress size={20} sx={{ alignSelf: 'center' }} />
                                    ) : (
                                        <>
                                            {items.length === 0 && <Typography sx={{ alignSelf: 'center' }}>{t('general.no_results')}</Typography>}
                                            <TreeView
                                                apiRef={treeViewApiRef}
                                                items={items}
                                                checkboxSelection={true}
                                                expandedItems={expandedItems}
                                                selectedItems={value?.id.toString() ?? getNull()}
                                                onItemExpansionToggle={(_, itemId, isExpanded) => {
                                                    handleItemExpansionToggle(itemId, isExpanded);
                                                }}
                                                onItemSelectionToggle={(_, itemId, isSelected) => {
                                                    handleItemSelectionToggle(itemId, isSelected);
                                                }}
                                            />
                                        </>
                                    )}
                                </Stack>
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
});

const BreadCrumb = ({ items, ...rootProps }: { items: RecursiveValue[] } & StackProps) => {
    return (
        <Stack direction='row' {...rootProps}>
            {items.map((item, index) => (
                <Fragment key={item.id}>
                    <Typography component='span' overflow='hidden' textOverflow='ellipsis' flexShrink={items.length - index}>
                        {item.label}
                    </Typography>
                    <Typography component='span'>/</Typography>
                </Fragment>
            ))}
        </Stack>
    );
};
