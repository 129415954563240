import { Alert, Button, DialogActions, DialogContent, Typography } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getNotificationFormSchema, NotificationFormSchema } from '@/page/setting/notification/notification.schema';
import { DefaultRealmNotificationUpdate } from '@/domain/default-realm-notification/DefaultRealmNotification.model';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { EmployeeNotification } from '@/domain/employee-notification/EmployeeNotification.model';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import { StackedAvatars } from '@/components/stacked-avatar/StackedAvatars';
import { NotificationsBlock } from '@/page/setting/notification/DefaultRealmNotificationPage';
import { Stack } from '@mui/system';
import { updateEmployeesNotifications } from '@/domain/employee-notification/EmployeeNotification.service';

type EmployeesNotificationsDialogProps = {
    employeesNotifications: EmployeeNotification[];
    onSave: () => void;
} & DialogWrapperProps;

export const EmployeesNotificationsDialog: FC<EmployeesNotificationsDialogProps> = props => {
    const { t } = useTranslation();

    const { employeesNotifications, onSave, onClose, ...otherProps } = props;

    const formMethods = useForm<NotificationFormSchema>({
        resolver: yupResolver(getNotificationFormSchema()),
        defaultValues: getDefaultValues(employeesNotifications),
    });

    const { handleSubmit } = formMethods;

    const employees = employeesNotifications.map(employeeNotification => employeeNotification.employee);

    const handleSave = async (data: NotificationFormSchema) => {
        const defaultRequest: DefaultRealmNotificationUpdate = {
            leaveNotificationsEnabled: data.leaveNotificationsEnabled ?? false,
            leaveAttachmentNotificationsEnabled: data.leaveAttachmentNotificationsEnabled ?? false,
            planningUpdatesNotificationsEnabled: data.planningUpdatesNotificationsEnabled ?? false,
            documentNotificationsEnabled: data.documentNotificationsEnabled ?? false,
            profileChangeNotificationsEnabled: data.profileChangeNotificationsEnabled ?? false,
            timesheetUpdatesNotificationsEnabled: data.timesheetUpdatesNotificationsEnabled ?? false,
            announcementNotificationsEnabled: data.announcementNotificationsEnabled ?? false,
        };

        const requests = employees.map(employee => ({
            employeeId: employee.id,
            ...defaultRequest,
        }));

        try {
            await updateEmployeesNotifications(requests);
            showSnackbar(t('default_realm_notification_page.on_save'), 'success');
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const handleClose = () => {
        onClose();
    };

    return (
        <DialogWrapper onClose={handleClose} header={t('employees_notifications_dialog.title')} {...otherProps}>
            <DialogContent>
                <Stack gap={2}>
                    <Alert severity='warning' sx={{ pt: 0 }}>
                        <Typography variant='h2'>{t('employees_notifications_dialog.warning_title')}</Typography>
                        <Typography variant='body2'>{t('employees_notifications_dialog.warning_description')}</Typography>
                    </Alert>
                    <FormProvider {...formMethods}>
                        <NotificationsBlock />
                    </FormProvider>
                </Stack>
            </DialogContent>
            <DialogActions>
                <StackedAvatars employeeAvatars={employees} />
                <Button onClick={handleSubmit(handleSave)}>{t('general.save')}</Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const getDefaultValues = (employeesNotifications: EmployeeNotification[]): NotificationFormSchema => {
    if (employeesNotifications.length === 1) {
        return {
            ...employeesNotifications[0],
        };
    } else {
        return {
            leaveNotificationsEnabled: false,
            leaveAttachmentNotificationsEnabled: false,
            planningUpdatesNotificationsEnabled: false,
            documentNotificationsEnabled: false,
            profileChangeNotificationsEnabled: false,
            timesheetUpdatesNotificationsEnabled: false,
            announcementNotificationsEnabled: false,
        };
    }
};
