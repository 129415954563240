import { Department, DepartmentNode } from '@/domain/department/Department.model';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';

import { RecursiveValue } from '@/components/tree-select/TreeSelect';

/**
 * Maps a department node to a list of departments (the department and its children)
 * @param department
 * @param parent
 */
export const mapDepartmentNodeToDepartment = (department: DepartmentNode, parent: DepartmentNode | undefined): Department[] => {
    if (department.children.length === 0) {
        return [mapDepartmentNodeToSingleDepartment(department, parent)];
    }
    return [mapDepartmentNodeToSingleDepartment(department, parent), ...department.children.flatMap(d => mapDepartmentNodeToDepartment(d, department))];
};

const mapDepartmentNodeToSingleDepartment = (department: DepartmentNode, parent: DepartmentNode | undefined): Department => {
    return {
        id: department.id,
        name: department.name,
        parentId: parent?.id,
        order: department.order,
        managers: department.managers,
        departmentCostCenters: department.departmentCostCenters,
    };
};

/**
 * Maps a list of department nodes to a list of departments (all departments at the root and their children)
 * @param departments
 */
export const mapDepartmentNodesListToDepartments = (departments: DepartmentNode[]): Department[] => {
    return departments.flatMap(department => mapDepartmentNodeToDepartment(department, undefined));
};

export const mapDepartmentToRecursiveValue = (department: Department, translationLanguage?: UserLanguage): RecursiveValue => ({
    id: department.id,
    label: getLabelTranslation(department.name, translationLanguage),
    children: [],
});

// Function to map Department list to RecursiveValue list
export const mapDepartmentsToRecursiveValues = (departments: Department[], translationLanguage?: UserLanguage): RecursiveValue[] => {
    const departmentMap: Record<number, RecursiveValue> = {};

    // Initialize all departments as RecursiveValue without children
    departments.forEach(dept => {
        departmentMap[dept.id] = mapDepartmentToRecursiveValue(dept, translationLanguage);
    });

    const rootNodes: RecursiveValue[] = [];

    // Populate children and find root nodes
    departments.forEach(dept => {
        const node = departmentMap[dept.id];

        if (dept.parentId !== undefined && departmentMap[dept.parentId]) {
            // If parent exists, add this department as a child
            departmentMap[dept.parentId].children.push(node);
        } else {
            // If no parentId, it's a root node
            rootNodes.push(node);
        }
    });

    return rootNodes;
};

export const mapDepartmentNodeToRecursiveValue = <T extends DepartmentNode | undefined>(
    department: T,
    translationLanguage?: UserLanguage,
): T extends undefined ? undefined : RecursiveValue => {
    if (!department) {
        return undefined as T extends undefined ? undefined : RecursiveValue;
    }
    return {
        id: department.id,
        label: getLabelTranslation(department.name, translationLanguage),
        children: department.children.map(child => mapDepartmentNodeToRecursiveValue(child, translationLanguage)),
    } as T extends undefined ? undefined : RecursiveValue;
};
