import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import {
    EmployeeReviewFeedbackSummaryMutation,
    EmployeeReviewManagerSummary,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import {
    createEmployeeReviewFeedbacksManagerSummary,
    createEmployeeReviewFeedbacksManagerSummaryItem,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.service';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { submitEmployeeReviewDiscussion } from '@/domain/employee-review/EmployeeReview.service';
import { Objective } from '@/domain/objective/Objective.model';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { EmployeeReviewFeedbackObjectives } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackObjectives';
import { EmployeeReviewFeedbackSection } from '@/page/review/employee-review-feedback-form/EmployeeReviewFeedbackSection';
import { EmployeeReviewFeedbackSummaryPrivateQuestion } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewFeedbackSummaryPrivateQuestion';
import { EmployeeReviewFeedbackSummaryQuestion } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewFeedbackSummaryQuestion';
import { EmployeeReviewFeedbackSummarySkills } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewFeedbackSummarySkills';
import {
    EmployeeReviewFeedbackSummarySkillFormType,
    employeeReviewSummaryFeedbackFormSchema,
    EmployeeReviewSummaryFeedbackFormType,
    EmployeeReviewSummaryFeedbackItemFormType,
    EmployeeReviewSummaryFeedbackQuestionFormType,
    EmployeeReviewSummaryFeedbackSkillQuestionFormType,
    EmployeeReviewSummaryFeedbackSkillsFormType,
} from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.schema';
import { mapEmployeeReviewSummaryFeedbackItem } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackForm.util';
import { EmployeeReviewSummaryFeedbackFormHeader } from '@/page/review/employee-review-summary-feedback-form/EmployeeReviewSummaryFeedbackFormHeader';
import { handleError } from '@/utils/api.util';
import { linkRef } from '@/utils/dom.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, DialogActions, DialogContent, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, MutableRefObject, useEffect, useRef, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

type EmployeeReviewSummaryFeedbackFormProps = {
    employeeReviewManagerSummary: EmployeeReviewManagerSummary;
    isFormDisabled: boolean;
    employeeId: number;
    employeeReview: EmployeeReview;
    scrollRef: MutableRefObject<HTMLDivElement | undefined>;
    objectives: Objective[];
    refetchObjectives: () => void;
};

export const EmployeeReviewSummaryFeedbackForm: FC<EmployeeReviewSummaryFeedbackFormProps> = ({
    employeeReviewManagerSummary,
    isFormDisabled,
    employeeReview,
    employeeId,
    scrollRef,
    objectives,
    refetchObjectives,
}) => {
    const itemsRefs = useRef<HTMLDivElement[]>([]);
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));
    const isTablet = useMediaQuery(useTheme().breakpoints.down('md'));
    const employeeReviewId = employeeReview.id;
    const [confirmSubmitFeedbackDialogOpen, setConfirmSubmitFeedbackDialogOpen] = useState(false);
    const navigate = useNavigate();
    const [expandAll, setExpandAll] = useState(true);

    const { t } = useTranslation();
    const onSaveItem = async (
        feedbackFormItem: EmployeeReviewSummaryFeedbackItemFormType,
        employeeReviewFeedbackSummarySkillForm?: EmployeeReviewFeedbackSummarySkillFormType,
    ) => {
        let employeeReviewFeedbackSummaryMutation: EmployeeReviewFeedbackSummaryMutation | undefined = undefined;

        switch (feedbackFormItem.type) {
            case 'SECTION':
                return;
            case 'SKILL':
                employeeReviewFeedbackSummaryMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: feedbackFormItem.score,
                    comment: feedbackFormItem.comment,
                    skillId: feedbackFormItem.skillId,
                    objectiveId: undefined,
                };
                break;
            case 'PRIVATE_EMPLOYEE_QUESTION':
            case 'PRIVATE_MANAGER_QUESTION':
            case 'QUESTION':
                employeeReviewFeedbackSummaryMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: feedbackFormItem.score,
                    comment: feedbackFormItem.comment,
                    skillId: undefined,
                    objectiveId: undefined,
                };
                break;
            case 'SKILLS':
                employeeReviewFeedbackSummaryMutation = {
                    reviewItemId: feedbackFormItem.id,
                    score: employeeReviewFeedbackSummarySkillForm?.score,
                    comment: employeeReviewFeedbackSummarySkillForm?.comment,
                    skillId: employeeReviewFeedbackSummarySkillForm?.skill.id,
                    objectiveId: undefined,
                };
                break;
        }

        if (!employeeReviewFeedbackSummaryMutation) {
            return;
        }

        try {
            await createEmployeeReviewFeedbacksManagerSummaryItem(employeeReviewId, employeeReviewFeedbackSummaryMutation);
        } catch (error) {
            handleError(error);
        }
    };

    const getDefaultValues = (employeeReviewManagerSummary: EmployeeReviewManagerSummary) => {
        const sortedFeedbackItems = (employeeReviewManagerSummary.items ?? [])
            .map(mapEmployeeReviewSummaryFeedbackItem)
            .sort((a, b) => (a?.order && b?.order ? a.order - b.order : 0));

        const privateQuestions = sortedFeedbackItems.filter(item => item.type === 'PRIVATE_EMPLOYEE_QUESTION' || item.type === 'PRIVATE_MANAGER_QUESTION');

        const nonPrivateQuestions = sortedFeedbackItems.filter(item => item.type !== 'PRIVATE_EMPLOYEE_QUESTION' && item.type !== 'PRIVATE_MANAGER_QUESTION');

        return {
            employeeReviewSummaryFeedbackItems: nonPrivateQuestions.concat(privateQuestions),
        };
    };

    const defaultValues = getDefaultValues(employeeReviewManagerSummary);
    const {
        handleSubmit,
        formState: { errors },
        watch,
        control,
    } = useForm<EmployeeReviewSummaryFeedbackFormType>({
        resolver: yupResolver(employeeReviewSummaryFeedbackFormSchema),
        defaultValues: defaultValues,
    });

    useEffect(() => {
        // we need to scroll to the first error
        if (!!Object.keys(errors)?.length && !!itemsRefs?.current?.length) {
            // we use the first error index to scroll to the first error
            const firstErrorIndex = errors?.employeeReviewSummaryFeedbackItems?.findIndex?.(item => !!item);
            if (!!firstErrorIndex || firstErrorIndex === 0) {
                itemsRefs.current[firstErrorIndex]?.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, [t, errors]);

    const { fields } = useFieldArray({
        control,
        name: 'employeeReviewSummaryFeedbackItems',
    });

    const openConfirmSubmitFeedbackDialog = () => {
        setConfirmSubmitFeedbackDialogOpen(true);
    };

    const submitDiscussion = async (employeeReviewSummaryFeedbackFormType: EmployeeReviewSummaryFeedbackFormType) => {
        const employeeReviewFeedbackSummaryMutations: EmployeeReviewFeedbackSummaryMutation[] =
            employeeReviewSummaryFeedbackFormType.employeeReviewSummaryFeedbackItems
                .filter(
                    (
                        item,
                    ): item is
                        | EmployeeReviewSummaryFeedbackQuestionFormType
                        | EmployeeReviewSummaryFeedbackSkillQuestionFormType
                        | EmployeeReviewSummaryFeedbackSkillsFormType =>
                        item?.type === 'QUESTION' ||
                        item?.type === 'SKILL' ||
                        item?.type === 'SKILLS' ||
                        item?.type === 'PRIVATE_EMPLOYEE_QUESTION' ||
                        item?.type === 'PRIVATE_MANAGER_QUESTION',
                )
                .map(item => {
                    switch (item.type) {
                        case 'SKILL':
                            return {
                                reviewItemId: item.id,
                                score: item.score,
                                comment: item.comment,
                                skillId: item.skillId,
                                objectiveId: undefined,
                            };
                        case 'PRIVATE_EMPLOYEE_QUESTION':
                        case 'PRIVATE_MANAGER_QUESTION':
                        case 'QUESTION':
                            return {
                                reviewItemId: item.id,
                                score: item.score,
                                comment: item.comment,
                                skillId: undefined,
                                objectiveId: undefined,
                            };
                        case 'SKILLS':
                            return item.skills.map(
                                skill =>
                                    ({
                                        reviewItemId: item.id,
                                        score: skill.score,
                                        comment: skill.comment,
                                        skillId: skill.skill.id,
                                        objectiveId: undefined,
                                    }) as EmployeeReviewFeedbackSummaryMutation,
                            );
                    }
                })
                .flat();

        try {
            await createEmployeeReviewFeedbacksManagerSummary(Number(employeeReviewId), employeeReviewFeedbackSummaryMutations);
            showSnackbar(t('reviews.review_summary.messages.discussion_submitted'), 'success');
            try {
                await submitEmployeeReviewDiscussion(employeeReviewId);
                setConfirmSubmitFeedbackDialogOpen(false);
                navigate('/reviews/team-reviews');
            } catch (error) {
                handleError(error);
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleSkillChanged = (
        employeeReviewSummaryFeedbackSkillForm: EmployeeReviewFeedbackSummarySkillFormType,
        value: EmployeeReviewSummaryFeedbackSkillsFormType,
        onChange: (value: EmployeeReviewSummaryFeedbackSkillsFormType) => void,
    ) => {
        const skillsItem = value;
        const updatedSkills = skillsItem.skills.map(skill => {
            if (skill.skill.id === employeeReviewSummaryFeedbackSkillForm.skill.id) {
                return employeeReviewSummaryFeedbackSkillForm;
            }
            return skill;
        });
        onChange({ ...skillsItem, skills: updatedSkills });
        onSaveItem(skillsItem, employeeReviewSummaryFeedbackSkillForm).catch(console.error);
    };

    return (
        <ContentContainer>
            <Box ref={scrollRef} />
            <Stack
                direction='column'
                sx={{ pb: isMobile ? 0 : 5 }}
                component={'form'}
                id={'employee-review-summary-feedback-form'}
                onSubmit={handleSubmit(openConfirmSubmitFeedbackDialog, console.error)}
            >
                <Stack direction='column' gap={2} width={isTablet ? '100%' : '1000px'} alignSelf={'center'}>
                    <EmployeeReviewSummaryFeedbackFormHeader
                        employeeReviewManagerSummary={employeeReviewManagerSummary}
                        objectives={objectives}
                        employeeReviewSummaryFeedbackItems={watch('employeeReviewSummaryFeedbackItems')}
                        expandAll={expandAll}
                        handleExpandAllChange={() => setExpandAll(prevExpandAll => !prevExpandAll)}
                        employeeReview={employeeReview}
                    />

                    <Stack spacing={2} direction='column'>
                        {fields.map((item, index) => {
                            switch (item?.type) {
                                case 'SECTION':
                                    return <EmployeeReviewFeedbackSection sectionItem={item} key={item.id} />;
                                case 'SKILL':
                                    return (
                                        <Controller
                                            name={`employeeReviewSummaryFeedbackItems.${index}`}
                                            control={control}
                                            key={item.id}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <EmployeeReviewFeedbackSummaryQuestion
                                                    innerRef={linkRef(index, itemsRefs)}
                                                    employeeReviewFeedbackSummaryQuestionForm={value as EmployeeReviewSummaryFeedbackQuestionFormType}
                                                    disabled={isFormDisabled}
                                                    error={error}
                                                    key={`${item?.type}_${index}`}
                                                    onUpdate={value => {
                                                        onChange(value);
                                                        onSaveItem(value).catch(console.error);
                                                    }}
                                                    expandAll={expandAll}
                                                />
                                            )}
                                        />
                                    );
                                case 'SKILLS':
                                    return (
                                        <Controller
                                            name={`employeeReviewSummaryFeedbackItems.${index}`}
                                            control={control}
                                            key={item.id}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <EmployeeReviewFeedbackSummarySkills
                                                    innerRef={linkRef(index, itemsRefs)}
                                                    skillsItem={value as EmployeeReviewSummaryFeedbackSkillsFormType}
                                                    handleSkillChanged={data =>
                                                        handleSkillChanged(data, value as EmployeeReviewSummaryFeedbackSkillsFormType, onChange)
                                                    }
                                                    error={error}
                                                    disabled={isFormDisabled}
                                                />
                                            )}
                                        />
                                    );
                                case 'OBJECTIVES':
                                    return (
                                        <EmployeeReviewFeedbackObjectives
                                            employeeId={employeeId}
                                            disabled={isFormDisabled}
                                            key={item.id}
                                            objectives={objectives}
                                            refetchObjectives={refetchObjectives}
                                        />
                                    );
                                case 'PRIVATE_EMPLOYEE_QUESTION':
                                case 'PRIVATE_MANAGER_QUESTION':
                                    return (
                                        <Controller
                                            name={`employeeReviewSummaryFeedbackItems.${index}`}
                                            control={control}
                                            key={item.id}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <EmployeeReviewFeedbackSummaryPrivateQuestion
                                                    innerRef={linkRef(index, itemsRefs)}
                                                    employeeReviewFeedbackSummaryQuestionForm={value as EmployeeReviewSummaryFeedbackQuestionFormType}
                                                    disabled={isFormDisabled}
                                                    error={error}
                                                    key={item.id}
                                                    onUpdate={value => {
                                                        onChange(value);
                                                        onSaveItem(value).catch(console.error);
                                                    }}
                                                />
                                            )}
                                        />
                                    );

                                case 'QUESTION':
                                default:
                                    return (
                                        <Controller
                                            name={`employeeReviewSummaryFeedbackItems.${index}`}
                                            control={control}
                                            key={item.id}
                                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                                                <EmployeeReviewFeedbackSummaryQuestion
                                                    innerRef={linkRef(index, itemsRefs)}
                                                    employeeReviewFeedbackSummaryQuestionForm={value as EmployeeReviewSummaryFeedbackQuestionFormType}
                                                    disabled={isFormDisabled}
                                                    error={error}
                                                    key={item.id}
                                                    onUpdate={value => {
                                                        onChange(value);
                                                        onSaveItem(value).catch(console.error);
                                                    }}
                                                    expandAll={expandAll}
                                                />
                                            )}
                                        />
                                    );
                            }
                        })}
                    </Stack>
                </Stack>
            </Stack>

            {confirmSubmitFeedbackDialogOpen && (
                <DialogWrapper
                    header={t('reviews.summary.confirm_submit_feedback_dialog_title')}
                    open={true}
                    onClose={() => setConfirmSubmitFeedbackDialogOpen(false)}
                >
                    <DialogContent>
                        <Typography variant='body2'>{t('reviews.summary.confirm_submit_feedback_dialog_subtitle_review_summary')}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button fullWidth onClick={handleSubmit(submitDiscussion, console.error)}>
                            {t('reviews.summary.confirm_submit_feedback_dialog_confirm')}
                        </Button>
                    </DialogActions>
                </DialogWrapper>
            )}
        </ContentContainer>
    );
};
