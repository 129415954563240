import { getFieldDefinitionTranslation } from '@/components/ag-grid-wrapper/column-types/columnTypes';
import { EmptyState } from '@/components/empty-state/EmptyState';
import { ObjectivesEmptyStateIcon } from '@/components/empty-state/EmptyStateIcons';
import { AsyncSelectFilter, FiltersBar, SelectFilter } from '@/components/filters-bar/FiltersBar';
import { getFilterValueIdsByKey, getNestedValueByPath } from '@/components/filters-bar/FiltersBar.util';
import { useFiltersStorage } from '@/components/filters-bar/useFiltersStorage';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { searchEmployees } from '@/domain/employee/Employee.service';
import { searchObjectiveCategories } from '@/domain/objective-category/ObjectiveCategory.service';
import { searchCompletionStatuses } from '@/domain/objective-completion-status/ObjectiveCompletionStatus.service';
import { Objective, OBJECTIVE_TYPE_TYPES, ObjectiveStatus } from '@/domain/objective/Objective.model';
import { getObjectiveStatusTranslationKey } from '@/domain/objective/Objective.service';
import { canCreateObjectives, canEditObjectives } from '@/domain/permission/Permission.service';
import { useGetObjectiveSetting } from '@/hooks/objective-setting/ObjectiveSetting.hook';
import { useGetObjectives } from '@/hooks/objective/Objective.hook';
import { AddObjectiveDialog } from '@/page/objective/add-objective-dialog/AddObjectiveDialog';
import { ObjectivesList } from '@/page/objective/objectives-list/ObjectivesList';
import { useCurrentEmployee, useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { Button, Divider, IconButton, Paper, Stack, Typography } from '@mui/material';
import { Add01Icon, ArrowDown02Icon, ArrowUp02Icon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

type Props = {
    isFromProfile?: boolean;
};

export const ObjectivesPage: FC<Props> = ({ isFromProfile = false }) => {
    const { t } = useTranslation();
    const params = useParams();
    const currentEmployee = useCurrentEmployee();
    const employeeId = params?.employeeId ? Number(params?.employeeId) : currentEmployee?.id;
    const policies = useCurrentPolicies();

    const [objectiveDialogOpen, setObjectiveDialogOpen] = useState<boolean>(false);
    const [showArchivedObjectives, setShowArchivedObjectives] = useState<boolean>(false);
    const [activeObjective, setActiveObjective] = useState<Objective>();

    const {
        data: objectiveSetting,
        isLoading: isLoadingObjectiveSetting,
        isError: isErrorObjectiveSetting,
        error: errorObjectiveSetting,
    } = useGetObjectiveSetting();

    const { filters: availableFilters } = useObjectivesFilters(
        {
            isFromProfile,
            objectiveCategoriesEnabled: objectiveSetting?.objectiveCategoriesEnabled ?? false,
        },
        !isLoadingObjectiveSetting,
    );
    const [filters, setFilters] = useFiltersStorage(isFromProfile ? 'employee-profile-objectives' : 'objectives', availableFilters);

    const {
        data: objectives = [],
        isLoading: isObjectivesLoading,
        error: objectivesError,
        isError: isObjectivesError,
        refetch: refetchObjectives,
    } = useGetObjectives(
        {
            statusCompletionIds: getFilterValueIdsByKey(filters, 'statusCompletionIds'),
            employeeIds: isFromProfile && employeeId ? [employeeId] : [],
            includeArchived: true,
        },
        { enabled: !!filters?.length },
    );

    if (!employeeId) {
        throw new Error('Employee not defined');
    }

    const handleEdit = (id: number) => {
        const editObjective = objectives.find(objective => objective.id === id);
        setActiveObjective(editObjective);
        setObjectiveDialogOpen(true);
    };

    const getObjectivesFiltered = () => {
        // if no filters are applied, we return the original data
        const filtersFilled = filters?.filter(
            filter =>
                !!filter.value?.length &&
                // statusCompletionIds is filtered on server side
                filter.key !== 'statusCompletionIds',
        );
        if (!filtersFilled?.length) {
            return objectives;
        }

        const isFilterMatched = (filter: AsyncSelectFilter | SelectFilter, row: Objective) => {
            const valueFromRow = getNestedValueByPath(row, filter.key);
            return filter.value?.find(option => option.value === valueFromRow);
        };

        return objectives.filter(row => {
            // if one of the filter is not matching, we don't want to display the row
            return filtersFilled.every(filter => isFilterMatched(filter, row));
        });
    };

    const nonArchivedObjectivesNonFiltered = objectives.filter(objective => !objective.archived);
    const objectivesFiltered = getObjectivesFiltered();
    const archivedObjectives = objectivesFiltered.filter(objective => objective.archived);
    const nonArchivedObjectives = objectivesFiltered.filter(objective => !objective.archived);

    const emptyState = (
        <EmptyState
            icon={<ObjectivesEmptyStateIcon />}
            flex={1}
            title={t('objectives.no_objectives')}
            subTitle={!canCreateObjectives(policies, employeeId) ? t('objectives.ask_your_manager_for_a_objective') : undefined}
            action={
                canCreateObjectives(policies, employeeId) ? (
                    <Button startIcon={<Add01Icon />} onClick={() => setObjectiveDialogOpen(true)}>
                        {t('objectives.objective')}
                    </Button>
                ) : undefined
            }
        />
    );

    const showFilters = !!nonArchivedObjectivesNonFiltered.length || !!getFilterValueIdsByKey(filters, 'statusCompletionIds').length;
    const handleShowHideArchived = () => {
        setShowArchivedObjectives(!showArchivedObjectives);
    };

    return (
        <Stack gap={2} flex={1}>
            {/* Filters and global actions */}
            <Stack component={Paper} gap={1} p={2} flex={1}>
                {showFilters && (
                    <Stack gap={2} p={1}>
                        <Stack display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
                            <Typography variant='h1' pl={1}>
                                {t('objectives.objectives')}
                            </Typography>
                            {canCreateObjectives(policies, employeeId) && (
                                <Button startIcon={<Add01Icon />} onClick={() => setObjectiveDialogOpen(true)}>
                                    {t('objectives.objective')}
                                </Button>
                            )}
                        </Stack>

                        <FiltersBar filters={filters} onFiltersChange={setFilters} />
                    </Stack>
                )}
                <StateHandler
                    isLoading={isObjectivesLoading || isLoadingObjectiveSetting}
                    isError={isObjectivesError || isErrorObjectiveSetting}
                    isEmpty={!nonArchivedObjectives?.length}
                    emptyStateComponent={emptyState}
                    error={objectivesError || errorObjectiveSetting}
                >
                    <ObjectivesList
                        objectives={nonArchivedObjectives}
                        onSuccess={() => refetchObjectives()}
                        onEditObjective={handleEdit}
                        editable={canEditObjectives(policies, employeeId)}
                        displayWeight={objectiveSetting?.objectiveWeightEnabled ?? false}
                        displayAvatarsMobile={isFromProfile}
                    />
                </StateHandler>
            </Stack>
            {archivedObjectives.length > 0 && (
                <Stack gap={2}>
                    <Divider sx={{ width: '100%' }}>
                        <IconButton aria-label='toggle archived objective visibility' onClick={handleShowHideArchived} edge='end' size='small'>
                            <Typography variant='body1'>{showArchivedObjectives ? t('objectives.hide_archived') : t('objectives.show_archived')}</Typography>
                            {showArchivedObjectives ? <ArrowUp02Icon /> : <ArrowDown02Icon />}
                        </IconButton>
                    </Divider>
                    {showArchivedObjectives && (
                        <Stack component={Paper} gap={2} flex={1} p={1}>
                            <Typography variant='h1' pl={1} p={2}>
                                {t('objectives.archived_objectives')}
                            </Typography>
                            <StateHandler
                                isLoading={isObjectivesLoading || isLoadingObjectiveSetting}
                                isError={isObjectivesError || isErrorObjectiveSetting}
                                isEmpty={!archivedObjectives?.length}
                                emptyStateComponent={emptyState}
                                error={objectivesError || errorObjectiveSetting}
                            >
                                <ObjectivesList
                                    objectives={archivedObjectives}
                                    onSuccess={() => refetchObjectives()}
                                    onEditObjective={handleEdit}
                                    editable={canEditObjectives(policies, employeeId)}
                                    displayWeight={objectiveSetting?.objectiveWeightEnabled ?? false}
                                    displayAvatarsMobile={isFromProfile}
                                />
                            </StateHandler>
                        </Stack>
                    )}
                </Stack>
            )}

            {objectiveDialogOpen && (
                <AddObjectiveDialog
                    open={objectiveDialogOpen}
                    activeObjective={activeObjective}
                    parentObjectiveEnabled={objectiveSetting?.parentObjectiveEnabled ?? false}
                    employeeId={activeObjective ? activeObjective?.assignee?.id : employeeId}
                    onSaveObjective={() => {
                        setActiveObjective(undefined);
                        refetchObjectives().catch(handleError);
                    }}
                    disabledEmployeeSelection={isFromProfile}
                    onClose={() => {
                        setActiveObjective(undefined);
                        setObjectiveDialogOpen(false);
                    }}
                />
            )}
        </Stack>
    );
};

const useObjectivesFilters = (
    { isFromProfile, objectiveCategoriesEnabled }: { isFromProfile: boolean; objectiveCategoriesEnabled: boolean },
    enabled = true,
) => {
    const { t } = useTranslation();

    if (!enabled) {
        return { filters: [] };
    }

    const filters: (AsyncSelectFilter | SelectFilter)[] = [
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'OBJECTIVE_COMPLETION_STATUS' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const statuses = await searchCompletionStatuses();
                return statuses.map(status => ({
                    label: getLabelTranslation(status.name),
                    value: status.id,
                }));
            },
            key: 'statusCompletionIds',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: t('objectives.status_label'),
            type: 'multi-select',
            selectMode: 'SYNC',
            options: Object.keys(ObjectiveStatus).map(status => ({
                label: t(getObjectiveStatusTranslationKey(status as ObjectiveStatus)),
                value: status,
            })),
            key: 'lastStatusUpdate.status',
            rule: 'EQUALS',
            availableRules: [],
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'OBJECTIVE_CATEGORY' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const categories = await searchObjectiveCategories();
                return categories.map(category => ({
                    label: getLabelTranslation(category.name),
                    value: category.id,
                }));
            },
            key: 'category.id',
            rule: 'EQUALS',
            availableRules: [],
            hide: !objectiveCategoriesEnabled,
        },
        {
            filterName: getFieldDefinitionTranslation({ fieldType: 'EMPLOYEE' }),
            type: 'multi-select',
            selectMode: 'ASYNC',
            fetchOptions: async () => {
                const employeesData = await searchEmployees();
                return employeesData.map(employee => ({
                    label: employee.displayName,
                    value: employee.id,
                }));
            },
            key: 'assignee.id',
            rule: 'EQUALS',
            availableRules: [],
            hide: isFromProfile,
        },
        {
            filterName: t('objectives.type_filter_label'),
            type: 'multi-select',
            selectMode: 'SYNC',
            options: OBJECTIVE_TYPE_TYPES.map(objectiveType => ({
                label: t('objectives.objective_type', { context: objectiveType }),
                value: objectiveType,
            })),
            key: 'objectiveType',
            rule: 'EQUALS',
            availableRules: [],
        },
    ];

    return { filters };
};
