import { FieldLabel } from '@/components/form/field-label/FieldLabel';
import { Department, DepartmentCreationMutation, DepartmentNode, DepartmentUpdateMutation } from '@/domain/department/Department.model';
import { createDepartment, deleteDepartment, updateDepartment, updateDepartmentOrders } from '@/domain/department/Department.service';
import { mapDepartmentNodesListToDepartments } from '@/domain/department/Department.utils';
import { Label } from '@/domain/label/Label.model';
import { isLabelUnique } from '@/domain/label/Label.service';
import { useGetDepartmentNodes } from '@/hooks/department/Department.hook';
import { DepartmentDialog } from '@/page/setting/organization/department/department-dialog/DepartmentDialog';
import { DepartmentFormValues, getDepartmentFormSchema } from '@/page/setting/organization/department/department-dialog/DepartmentDialog.schema';
import { CustomTreeViewBaseItem, DepartmentTreeItem } from '@/page/setting/organization/department/department-tree/DepartmentTreeItem';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation, UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button } from '@mui/material';
import { Stack } from '@mui/system';
import { AddSquareIcon, RemoveSquareIcon } from 'hugeicons-react';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { TreeView, TreeViewItem } from '@/components/tree-view/TreeView';
import { DepartmentUpdateEmploymentDialog } from '@/page/setting/organization/department/employment-update-dialog/DepartmentUpdateEmploymentDialog';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { TreeViewItemReorderPosition } from '@mui/x-tree-view-pro/internals/plugins/useTreeViewItemsReordering/useTreeViewItemsReordering.types';
import { findItemById } from '@/components/tree-select/TreeSelect.util';
import { OrderMutation } from '@/domain/common';

export const DepartmentsTree: FC<{ translationLanguage: UserLanguage }> = ({ translationLanguage }) => {
    const [parentToAdd, setParentToAdd] = useState<DepartmentNode>();
    const [departmentToUpdate, setDepartmentToUpdate] = useState<DepartmentNode>();

    const [prevDepartmentToUpdateEmployment, setPrevDepartmentToUpdateEmployment] = useState<DepartmentNode>();
    const [newDepartmentToUpdateEmployment, setNewDepartmentToUpdateEmployment] = useState<Department>();
    const { t } = useTranslation();

    // Fetching data
    const { data: departmentNodes = [], refetch: refetchDepartmentsNodes } = useGetDepartmentNodes();
    const flatDepartments = mapDepartmentNodesListToDepartments(departmentNodes);
    const items = mapHierarchyToTreeItems(departmentNodes, translationLanguage);

    const { data: employeesInDepartment = [], isLoading: isEmployeesInDepartmentLoading } = useGetEmployees(
        { departmentIds: prevDepartmentToUpdateEmployment?.id ? [prevDepartmentToUpdateEmployment?.id] : [] },
        {
            enabled: !!prevDepartmentToUpdateEmployment,
        },
    );

    // Handle actions click
    const handleAddNodeClick = (department: DepartmentNode) => {
        setParentToAdd(department);
    };

    const handleEditNodeClick = (department: DepartmentNode) => {
        setDepartmentToUpdate(department);
    };

    const handleDeleteNodeClick = (department: DepartmentNode) => {
        handleSubmitDelete(department.id).catch(handleError);
    };

    // Submitting data
    const handleSubmitAdd = async (departmentFormValue: DepartmentFormValues) => {
        const mutation = { ...mapDepartmentFormValuesToMutation(departmentFormValue), order: items.length };

        const filteredLabels = getLabelsByDepartmentsAndParent(flatDepartments, mutation.parentId);

        if (!isLabelUnique(mutation.name, filteredLabels)) {
            showSnackbar(t('settings_organization.departments.unique_name_error'), 'error');
            return;
        }
        try {
            await createDepartment(mutation);
            setParentToAdd(undefined);
            refetchDepartmentsNodes().catch(handleError);
            reset();
        } catch (error) {
            handleError(error);
        }
    };

    const handleSubmitUpdate = async (department: DepartmentNode, departmentFormValue: DepartmentFormValues) => {
        const mutation = { ...mapDepartmentFormValuesToMutation(departmentFormValue), order: department.order };

        const filteredLabels = getLabelsByDepartmentsAndParent(
            flatDepartments.filter(d => department.id !== d.id),
            mutation.parentId,
        );

        if (!isLabelUnique(departmentFormValue.name, filteredLabels)) {
            showSnackbar(t('settings_organization.departments.unique_name_error'), 'error');
            return;
        }
        try {
            const newDepartment = await updateDepartment(department.id, mutation);
            setDepartmentToUpdate(undefined);
            setPrevDepartmentToUpdateEmployment(department);
            setNewDepartmentToUpdateEmployment(newDepartment);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const handleSubmitDelete = async (departmentId: DepartmentNode['id']) => {
        try {
            await deleteDepartment(departmentId);
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    const isSameCostCenters = (prevDepartment: DepartmentNode, newDepartment: Department) =>
        prevDepartment.departmentCostCenters.length === newDepartment.departmentCostCenters.length &&
        newDepartment.departmentCostCenters.every(newCostCenter => {
            const prevCostCenter = prevDepartment.departmentCostCenters.find(prevDcc => prevDcc.costCenter.id === newCostCenter.costCenter.id);
            return prevCostCenter?.percentage === newCostCenter.percentage;
        });

    const isSameManagers = (prevDepartment: DepartmentNode, newDepartment: Department) =>
        prevDepartment.managers.length === newDepartment.managers.length &&
        newDepartment.managers.every(newManager => prevDepartment.managers.find(prevManager => prevManager.id === newManager.id));

    // return true if the department contains cost centers or managers have changed
    const openEmploymentUpdateDialog = (prevDepartment: DepartmentNode, newDepartment: Department) => {
        if (isEmployeesInDepartmentLoading) {
            return false;
        }
        return !!employeesInDepartment.length && (!isSameCostCenters(prevDepartment, newDepartment) || !isSameManagers(prevDepartment, newDepartment));
    };
    // Form to add department at the root
    const { control, handleSubmit, reset } = useForm({
        resolver: yupResolver(getDepartmentFormSchema(translationLanguage)),
    });

    const handleItemPositionChange = async (params: { itemId: string; oldPosition: TreeViewItemReorderPosition; newPosition: TreeViewItemReorderPosition }) => {
        const { itemId, oldPosition, newPosition } = params;
        const departmentId = Number(itemId);
        const newParentId = newPosition.parentId ? Number(newPosition.parentId) : undefined;

        try {
            if (oldPosition.parentId !== newPosition.parentId) {
                const department = flatDepartments.find(dep => dep.id === departmentId);
                if (!department) {
                    return;
                }
                await moveIntoOtherParent(items, department, newParentId, newPosition.index);
            } else {
                await moveIntoSameParent(items, newParentId, oldPosition.index, newPosition.index);
            }
            await refetchDepartmentsNodes();
        } catch (error) {
            handleError(error);
        }
    };

    return (
        <Stack gap={3} mt={2}>
            {/* FORM TO ADD DEPARTMENT */}
            <Stack direction={'row'} alignItems={'flex-start'} gap={2}>
                <FieldLabel
                    name={'name'}
                    control={control}
                    fullWidth
                    language={translationLanguage}
                    placeholder={t('settings_organization.departments.add_dialog.name_field')}
                />
                <Button
                    onClick={() => {
                        handleSubmit((data: DepartmentFormValues) => {
                            handleSubmitAdd(data).catch(handleError);
                        }, console.error)();
                    }}
                >
                    {t('general.submit')}
                </Button>
            </Stack>

            {/* DEPARTMENT TREE */}
            <TreeView
                items={items}
                itemsReordering={true}
                onItemPositionChange={handleItemPositionChange}
                slots={{
                    expandIcon: AddSquareIcon,
                    collapseIcon: RemoveSquareIcon,
                    item: DepartmentTreeItem,
                }}
                slotProps={{
                    expandIcon: { width: 16, height: 16 },
                    collapseIcon: { width: 16, height: 16 },
                    item: ownerState => {
                        return {
                            ...ownerState,
                            onAddClick: handleAddNodeClick,
                            onEditClick: handleEditNodeClick,
                            onDeleteClick: handleDeleteNodeClick,
                        };
                    },
                }}
            />

            {/* DIALOGS */}
            {parentToAdd && (
                <DepartmentDialog
                    open={true}
                    onClose={() => setParentToAdd(undefined)}
                    departments={departmentNodes}
                    defaultParent={parentToAdd}
                    translationLanguage={translationLanguage}
                    onSubmitDepartment={handleSubmitAdd}
                />
            )}
            {departmentToUpdate && (
                <DepartmentDialog
                    open={true}
                    onClose={() => setDepartmentToUpdate(undefined)}
                    departments={departmentNodes}
                    defaultDepartment={departmentToUpdate}
                    translationLanguage={translationLanguage}
                    onSubmitDepartment={(data: DepartmentFormValues) => handleSubmitUpdate(departmentToUpdate, data)}
                />
            )}
            {prevDepartmentToUpdateEmployment && newDepartmentToUpdateEmployment && (
                <DepartmentUpdateEmploymentDialog
                    open={openEmploymentUpdateDialog(prevDepartmentToUpdateEmployment, newDepartmentToUpdateEmployment)}
                    onClose={() => setPrevDepartmentToUpdateEmployment(undefined)}
                    prevDepartment={prevDepartmentToUpdateEmployment}
                    newDepartment={newDepartmentToUpdateEmployment}
                    employeesInDepartment={employeesInDepartment}
                    sameManagers={isSameManagers(prevDepartmentToUpdateEmployment, newDepartmentToUpdateEmployment)}
                    sameCostCenters={isSameCostCenters(prevDepartmentToUpdateEmployment, newDepartmentToUpdateEmployment)}
                />
            )}
        </Stack>
    );
};

const mapDepartmentFormValuesToMutation = (departmentFormValue: DepartmentFormValues): Omit<DepartmentCreationMutation, 'order'> => {
    const { name, parent, managers, departmentCostCenters } = departmentFormValue;
    return {
        name,
        parentId: parent?.id,
        managerIds: managers.map(m => m.id),
        departmentCostCenters: departmentCostCenters.map(dcc => ({
            costCenterId: dcc.costCenter.id,
            percentage: dcc.percentage,
        })),
    };
};

const mapHierarchyToTreeItems = (departments: DepartmentNode[], translationLanguage: UserLanguage): CustomTreeViewBaseItem[] => {
    return [...departments]
        .sort((d1, d2) => (d1.order >= d2.order ? 1 : -1))
        .map(department => {
            return {
                id: department.id,
                label: getLabelTranslation(department.name, translationLanguage),
                children: mapHierarchyToTreeItems(department.children, translationLanguage),
                originalData: department,
            };
        });
};

const getLabelsByDepartmentsAndParent = (departments: Department[], parentId: number | undefined): Label[] => {
    return departments.filter(d => (!d.parentId ? !parentId : parentId === d.parentId)).map(d => d.name);
};

const reOrderDepartments = async (orderMutations: OrderMutation[]) => {
    if (orderMutations.length === 0) {
        return;
    }
    try {
        await updateDepartmentOrders(orderMutations);
    } catch (error) {
        handleError(error);
    }
};

const moveIntoSameParent = async (items: TreeViewItem[], parentId: number | undefined, oldIndex: number, newIndex: number) => {
    // an undefined parentId means the department is at the root
    const children = parentId ? (findItemById(items, parentId)?.children ?? []) : items;
    const updatedReorderItems = [...children];
    updatedReorderItems.splice(oldIndex, 1);
    updatedReorderItems.splice(newIndex, 0, children[oldIndex]);

    try {
        const orderMutations = updatedReorderItems.map((item, index) => ({
            resourceId: item.id,
            order: index,
        }));
        await reOrderDepartments(orderMutations);
    } catch (error) {
        handleError(error);
    }
};

const moveIntoOtherParent = async (items: TreeViewItem[], department: Department, newParentId: number | undefined, newIndex: number) => {
    const departmentId = department.id;
    const item = findItemById(items, departmentId);
    if (!item) {
        return;
    }

    // change the department parent
    try {
        const updateMutation: DepartmentUpdateMutation = {
            name: department.name,
            order: newIndex,
            parentId: newParentId,
            managerIds: department.managers.map(m => m.id),
            departmentCostCenters: department.departmentCostCenters.map(dcc => ({
                costCenterId: dcc.costCenter.id,
                percentage: dcc.percentage,
            })),
        };
        await updateDepartment(departmentId, updateMutation);
    } catch (error) {
        handleError(error);
    }

    // build mutations of the previous children it was in
    // an undefined parentId means the department is at the root
    const childrenSource = department.parentId ? (findItemById(items, department.parentId)?.children ?? []) : items;
    const childrenSourceOrderMutation = childrenSource
        .filter(child => child.id !== department.id) // exclude the moved item
        .map((child, index) => ({
            resourceId: child.id,
            order: index,
        }));

    // build mutations of the new children with the moved item
    // an undefined parentId means the department is at the root
    const childrenDestination = newParentId ? (findItemById(items, newParentId)?.children ?? []) : items;
    const newChildrenDestination = [...childrenDestination];
    newChildrenDestination.splice(newIndex, 0, item); // insert the moved item

    const childrenDestinationOrderMutation = newChildrenDestination.map((child, index) => ({
        resourceId: child.id,
        order: index,
    }));

    // reorder separately the source and destination children (each branch of the tree has its own order)
    try {
        await reOrderDepartments([...childrenSourceOrderMutation, ...childrenDestinationOrderMutation]);
    } catch (error) {
        handleError(error);
    }
};
