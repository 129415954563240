import { EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { ImportRequest, ImportResult } from '@/domain/import/Import.model';
import {
    Objective,
    ObjectiveCloseMutation,
    ObjectiveCreateMutation,
    ObjectiveSearch,
    ObjectiveStatusCreateMutation,
    ObjectiveStatusUpdate,
    ObjectiveStatusUpdateMutation,
    ObjectiveUpdateMutation,
} from '@/domain/objective/Objective.model';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, buildImportFormData, client } from '../common';

export type ObjectiveDTO = Omit<Objective, 'assignee' | 'parent' | 'completedAt' | 'lastStatusUpdate' | 'statusUpdates' | 'createdAt'> & {
    assignee: EmployeeDTO;
    parent: ObjectiveDTO | undefined;
    completedAt: string;
    createdAt: string;
    lastStatusUpdate: ObjectiveStatusUpdateDTO;
    statusUpdates: ObjectiveStatusUpdateDTO[];
};
export type ObjectiveStatusUpdateDTO = Omit<ObjectiveStatusUpdate, 'createdBy' | 'updatedAt' | 'createdAt'> & {
    createdBy: EmployeeDTO;
    updatedAt: string;
    createdAt: string;
};

type ObjectiveCreateRequestDTO = DateToString<ObjectiveCreateMutation>;
type ObjectiveUpdateRequestDTO = DateToString<ObjectiveUpdateMutation>;
type ObjectiveCloseRequestDTO = ObjectiveCloseMutation;
type ObjectiveStatusCreateRequestDTO = ObjectiveStatusCreateMutation;
type ObjectiveStatusUpdateRequestDTO = ObjectiveStatusUpdateMutation;

const updateObjective = async (objectiveId: number, mutation: ObjectiveUpdateMutation): Promise<Objective> => {
    const { data } = await client.put<ObjectiveDTO, AxiosResponse<ObjectiveDTO>, ObjectiveUpdateRequestDTO>(
        API_BASE_URL + `/objectives/${objectiveId}`,
        mutation,
    );
    return mapObjectiveDTO(data);
};

const getObjective = async (objectiveId: number): Promise<Objective> => {
    const { data } = await client.get<ObjectiveDTO>(API_BASE_URL + `/objectives/${objectiveId}`);
    return mapObjectiveDTO(data);
};

const deleteObjective = (objectiveId: number): Promise<void> => {
    return client.delete(API_BASE_URL + `/objectives/${objectiveId}`);
};

const closeObjective = async (objectiveId: number, request: ObjectiveCloseMutation): Promise<Objective> => {
    const { data } = await client.post<ObjectiveDTO, AxiosResponse<ObjectiveDTO>, ObjectiveCloseRequestDTO>(
        API_BASE_URL + `/objectives/${objectiveId}/close`,
        request,
    );
    return mapObjectiveDTO(data);
};

const reopenObjective = async (objectiveId: number): Promise<Objective> => {
    const { data } = await client.post<ObjectiveDTO, AxiosResponse<ObjectiveDTO>, number>(API_BASE_URL + `/objectives/${objectiveId}/reopen`);
    return mapObjectiveDTO(data);
};

const archiveObjective = async (objectiveId: number): Promise<Objective> => {
    const { data } = await client.post<ObjectiveDTO, AxiosResponse<ObjectiveDTO>>(API_BASE_URL + `/objectives/${objectiveId}/archive`);
    return mapObjectiveDTO(data);
};

const unarchiveObjective = async (objectiveId: number): Promise<Objective> => {
    const { data } = await client.post<ObjectiveDTO, AxiosResponse<ObjectiveDTO>>(API_BASE_URL + `/objectives/${objectiveId}/unarchive`);
    return mapObjectiveDTO(data);
};

const createObjective = async (mutation: ObjectiveCreateMutation): Promise<Objective[]> => {
    const { data } = await client.post<ObjectiveDTO[], AxiosResponse<ObjectiveDTO[]>, ObjectiveCreateRequestDTO>(API_BASE_URL + `/objectives`, mutation);
    return mapObjectivesDTO(data);
};

const searchObjectives = async (request: ObjectiveSearch): Promise<Objective[]> => {
    const { data } = await client.post<ObjectiveDTO[], AxiosResponse<ObjectiveDTO[]>, ObjectiveSearch>(API_BASE_URL + `/objectives/search`, request);
    return data.map(mapObjectiveDTO);
};

const createObjectiveStatus = async (objectiveId: number, request: ObjectiveStatusCreateMutation): Promise<ObjectiveStatusUpdate> => {
    const { data } = await client.post<ObjectiveStatusUpdateDTO, AxiosResponse<ObjectiveStatusUpdateDTO>, ObjectiveStatusCreateRequestDTO>(
        API_BASE_URL + `/objectives/${objectiveId}/status`,
        request,
    );
    return mapObjectiveStatusUpdateDTO(data);
};

const updateObjectiveStatus = async (
    objectiveId: number,
    objectiveStatusId: number,
    mutation: ObjectiveStatusUpdateMutation,
): Promise<ObjectiveStatusUpdate> => {
    const { data } = await client.put<ObjectiveStatusUpdateDTO, AxiosResponse<ObjectiveStatusUpdateDTO>, ObjectiveStatusUpdateRequestDTO>(
        API_BASE_URL + `/objectives/${objectiveId}/status/${objectiveStatusId}`,
        mutation,
    );
    return mapObjectiveStatusUpdateDTO(data);
};

const deleteObjectiveStatus = (objectiveId: number, objectiveStatusId: number): Promise<void> => {
    return client.delete(API_BASE_URL + `/objectives/${objectiveId}/status/${objectiveStatusId}`);
};

const importObjectives = async (request: ImportRequest): Promise<ImportResult> => {
    const formData = buildImportFormData(request);
    return (await client.postForm<ImportResult, AxiosResponse<ImportResult>, FormData>(API_BASE_URL + `/objectives/import`, formData)).data;
};

export const objectiveApi = {
    updateObjective,
    deleteObjective,
    closeObjective,
    reopenObjective,
    archiveObjective,
    unarchiveObjective,
    createObjective,
    searchObjectives,
    createObjectiveStatus,
    updateObjectiveStatus,
    deleteObjectiveStatus,
    getObjective,
    importObjectives,
};

const mapObjectiveStatusUpdateDTO = (statusUpdate: ObjectiveStatusUpdateDTO): ObjectiveStatusUpdate => {
    return {
        ...statusUpdate,
        createdBy: statusUpdate.createdBy ? mapEmployeeDTO(statusUpdate.createdBy) : undefined,
        updatedAt: convertUTCIsoStringToDate(statusUpdate.updatedAt),
        createdAt: convertUTCIsoStringToDate(statusUpdate.createdAt),
    };
};

const mapParentObjectiveDTO = (parent: ObjectiveDTO): Objective => {
    return {
        ...mapObjectiveDTO(parent),
    };
};

export const mapObjectiveDTO = (objective: ObjectiveDTO): Objective => {
    return {
        ...objective,
        parent: objective.parent ? mapParentObjectiveDTO(objective.parent) : undefined,
        completedAt: convertUTCIsoStringToDate(objective.completedAt),
        createdAt: convertUTCIsoStringToDate(objective.createdAt),
        lastStatusUpdate: objective.lastStatusUpdate ? mapObjectiveStatusUpdateDTO(objective.lastStatusUpdate) : undefined,
        statusUpdates: objective.statusUpdates.map(mapObjectiveStatusUpdateDTO),
        assignee: mapEmployeeDTO(objective.assignee),
    };
};

export const mapObjectivesDTO = (objectives: ObjectiveDTO[]): Objective[] => {
    return objectives.map(mapObjectiveDTO);
};
